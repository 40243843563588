<template>
  <div class="p-2">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <a-form :form="form">
          <a-form-item
            label="First Name"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-input
              pattern="[A-Za-z]\w+"
              placeholder="Please enter your first name"
              v-decorator="[
                'first_name',
                {
                  rules: [
                    { required: true, message: 'First Name is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item
            label="Last Name"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-input
              pattern="[A-Za-z]\w+"
              placeholder="Please enter your last name"
              v-decorator="[
                'last_name',
                {
                  rules: [
                    { required: true, message: 'Last Name is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item
            label="Country"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-select
              ref="country"
              @change="handleCountrySelect"
              show-search
              :filter-option="filterOptions"
              option-filter-prop="country"
              v-decorator="[
                'address.country',
                {
                  rules: [{ required: true, message: 'Country is required!' }],
                },
              ]"
              placeholder="Please select your country"
            >
              <a-select-option
                :key="index"
                v-for="(countryObject, index) in countries"
                :value="countryObject.geo_name_id"
              >
                {{ countryObject.country }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-button @click="handleSubmit" type="primary" html-type="submit">
              Complete account
            </a-button>
          </a-form-item>
          <a-row> </a-row>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Persist from "@/mixins/Persist";
import { cloneDeep } from "lodash";
import { MISC_GET_GEO_DATA } from "@/store/actions";

export default {
  mixins: [Persist],
  data() {
    return {
      form: this.$form.createForm(this, { name: "basicinformation" }),
      country: {},

      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
  },
  computed: {
    ...mapGetters("misc", ["getCountriesList"]),
    basicInformationFromStore() {
      return this.$getFromLocal("basicInformation");
    },
    countries() {
      return this.getCountriesList;
    },
  },
  methods: {
    ...mapActions("misc", {
      getGeoData: MISC_GET_GEO_DATA,
    }),
    coerceAddressValue(countryStateCity) {
      try {
        const countryStructure = (address) => {
          return {
            geo_id: address.geo_name_id,
            latitude: address.latitude,
            longitude: address.longitude,
            name: address.country,
            iso_code: address.iso_code,
          };
        };
        if (countryStateCity.country) {
          const data = this.getCountriesList.find(
            (country) => countryStateCity.country == country.geo_name_id
          );
          countryStateCity.country = countryStructure(data);
        }
        return countryStateCity;
      } catch (e) {
        console.log(e);
      }
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const modifiedValues = cloneDeep(values);
          const { address } = modifiedValues;
          modifiedValues.address = this.coerceAddressValue(address);
          const data = { user: modifiedValues };
          this.$emit("next", data);
          this.$storeInLocal("basicInformation", values);
        }
      });
    },
    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleCountrySelect(selected) {
      this.country = this.countries.find(
        (country) => country.geo_name_id === selected
      );
    },
  },
  async mounted() {
    if (this.basicInformationFromStore) {
      this.$nextTick().then(() => {
        // this.basicInformationFromStore.address.state = '1147745'
        this.form.setFieldsValue(this.basicInformationFromStore);
        this.handleSubmit();
      });
    }
  },
};
</script>
