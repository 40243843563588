<template>
  <div>
    <a-spin :spinning="loading">
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>

      <div class="steps-content">
        <basic-information
          @next="completeOnboarding($event, current)"
          v-if="current === 0"
        />
      </div>

      <div class="steps-action"></div>
    </a-spin>
  </div>
</template>
<script>
import BasicInformation from "./basic-information.vue";

import { MISC_GET_COUNTRIES } from "@/store/actions";

import { mapActions } from "vuex";
import { merge } from "lodash";

import Profile from "@/mixins/Profile";

export default {
  mixins: [Profile],
  components: { BasicInformation },
  data() {
    return {
      loading: false,

      current: 0,
      steps: [
        {
          title: "Please complete your account by filling the form below",
        },
      ],
      accountData: {},
    };
  },
  computed: {},
  watch: {
    "account.has_completed_onboarding": {
      handler(completed) {
        if (completed) {
          //in others we relied on profile,since tourist does not have that users get mutated alot hence a loop,so just window.location is better
          return (window.location.href = "/tourist/gta-facilities");
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getCountries();
  },
  methods: {
    ...mapActions("misc", {
      getCountries: MISC_GET_COUNTRIES,
    }),
    async completeOnboarding(data) {
      try {
        this.loading = true;
        if (data) {
          this.accountData = merge(this.accountData, data);
        }
        await this.completeTouristOnboarding(this.accountData.user);
      } catch (e) {
        console.log("Error onboarding tourist", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
