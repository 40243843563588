var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 mx-auto"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"First Name","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'first_name',
              {
                rules: [
                  { required: true, message: 'First Name is required!' },
                ],
              },
            ]),expression:"[\n              'first_name',\n              {\n                rules: [\n                  { required: true, message: 'First Name is required!' },\n                ],\n              },\n            ]"}],attrs:{"pattern":"[A-Za-z]\\w+","placeholder":"Please enter your first name"}})],1),_c('a-form-item',{attrs:{"label":"Last Name","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'last_name',
              {
                rules: [
                  { required: true, message: 'Last Name is required!' },
                ],
              },
            ]),expression:"[\n              'last_name',\n              {\n                rules: [\n                  { required: true, message: 'Last Name is required!' },\n                ],\n              },\n            ]"}],attrs:{"pattern":"[A-Za-z]\\w+","placeholder":"Please enter your last name"}})],1),_c('a-form-item',{attrs:{"label":"Country","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address.country',
              {
                rules: [{ required: true, message: 'Country is required!' }],
              },
            ]),expression:"[\n              'address.country',\n              {\n                rules: [{ required: true, message: 'Country is required!' }],\n              },\n            ]"}],ref:"country",attrs:{"show-search":"","filter-option":_vm.filterOptions,"option-filter-prop":"country","placeholder":"Please select your country"},on:{"change":_vm.handleCountrySelect}},_vm._l((_vm.countries),function(countryObject,index){return _c('a-select-option',{key:index,attrs:{"value":countryObject.geo_name_id}},[_vm._v(" "+_vm._s(countryObject.country)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v(" Complete account ")])],1),_c('a-row')],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }