// local persist data
import { get } from 'lodash'
export default {
  computed: {
    userId() {
      return get(this.$store.state, 'auth.user._id')
    },
  },
  methods: {
    $storeInLocal(key, values) {
      if (key && values && this.userId) {
        if (typeof values !== 'string') {
          values = JSON.stringify(values)
        }
        localStorage.setItem(`${this.userId}-${key}`, values)
      }
    },
    $getFromLocal(key) {
      return JSON.parse(localStorage.getItem(`${this.userId}-${key}`))
    },
  },
}
