
import { mapActions, mapState } from 'vuex'
import {
  UPDATE_PROFILE,
  GET_PROFILE,
  COMPLETE_ONBOARDING,
  UPDATE_PROFILE_SERVICE,
  DELETE_PROFILE_SERVICE,
  UPDATE_PROFILE_BANK_INFORMATION,
  COMPLETE_TOURIST_ONBOARDING,
} from '@/store/actions'
import { getUserAccountProfileModule } from "@/store/utils"

export default {
  computed: {
    ...mapState('auth', ['authorized', 'user']),
    account() {
      if (this.accountIsTourist) return this.user
      return this.$store.getters[`${getUserAccountProfileModule()}/account`]
    },
    media() {
      return this.$store.getters[`${this.getUserAccountProfileModule}/media`]
    },
    accountIsTourSight() {
      return this.user.user_type === 'tour_sight'
    },
    accountIsTourOperator() {
      return this.user.user_type === "tour_operator"
    },
    accountIsAccommodation() {
      return this.user.user_type === "hotel_and_accommodations"
    },
    accountIsTourist() {
      return this.user.user_type === "tourist"
    }
  },
  methods: {
    ...mapActions('profile', {
      updateProfile: UPDATE_PROFILE,
      updateProfileService: UPDATE_PROFILE_SERVICE,
      deleteProfileService: DELETE_PROFILE_SERVICE,
      getProfile: GET_PROFILE,
      completeProfileOnboarding: COMPLETE_ONBOARDING,
      updateProfileBankInformation: UPDATE_PROFILE_BANK_INFORMATION,
      completeTouristOnboarding: COMPLETE_TOURIST_ONBOARDING,
    })
  },
}
